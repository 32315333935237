<template>
  <div class="container">
    <div class="mt-7">
      <div class="row">
        <div class="col mt-4 mx-auto"><signup /></div>
        <div class="col col-md-5 d-none d-lg-flex">
          <img
            src="@/assets/img/ill/search_round.svg"
            style="height:70vh"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Signup from "@/components/Signup.vue";
export default {
  bodyClass: "login-page",
  components: {
    Signup
  },
  metaInfo: () => ({
    title: "Sign Up"
  })
};
</script>
